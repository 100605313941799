import React from "react";
import videoBg from "../../assets/first_block_Trim.mp4";
import logo from "../../assets/Group.svg";
import scss from "./Main.module.scss";

import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Scrollbar, EffectFade } from "swiper";
import { subTitles } from "../../contants/subTitlesForMain";

export const Main = ({ setModalOpen }) => {
  return (
    <>
      <div className={scss.main}>
        <header className="container">
          <img src={logo} alt="logo" />
          <div>
            <a
              href="tel: +7 (985) 008 84 84"
              onClick={() => {
                window.ym(91747434,'reachGoal','najal_it_zentr');
              }}
            >
              +7 (985) <span className="blue">008 84 84</span>
            </a>
            <span>Связаться с нами</span>
            <div className={scss.links}>
              <a
                target="_blank"
                href="https://api.whatsapp.com/send/?phone=79859207799&text&type=phone_number&app_absent=0"
                rel="noopener noreferrer"
              >
                WhatsApp
              </a>
              <a
                target="_blank"
                href="https://t.me/recruiting_search_1ceo"
                rel="noopener noreferrer"
              >
                Telegram
              </a>
            </div>
          </div>
        </header>
        <div className={scss.it1}>
          <span></span>
          <h2 style={{ textShadow: "0px 0px 35px #00f4e5" }}>1it</h2>
          <span></span>
        </div>
        <div className={scss.title}>
          <h1>Рекрутинговое агентство</h1>
        </div>
        <div
          className={`container ${scss.slider_wrapper}`}
          style={{
            flexGrow: 1,
            position: "relative",
            paddingBottom: window.innerWidth > 768 ? "100px" : "75px",
          }}
        >
          <div className={scss.slider} aria-disabled="true">
            <Swiper
              slidesPerView={1}
              scrollbar={{
                hide: false,
              }}
              modules={[Autoplay, Scrollbar, EffectFade]}
              className="mySwiper"
              speed={2000}
              loop={true}
              effect={"fade"}
              fadeEffect={{ crossFade: true }}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
            >
              {subTitles.map((item) => (
                <SwiperSlide key={item.key}>
                  <div className={scss.slider_card}>
                    <p>{item.p}</p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <button
            className={`btn btn_v1 ${scss.button}`}
            onClick={() => setModalOpen(true)}
          >
            Найти
          </button>
        </div>
        <video src={videoBg} autoPlay loop muted />
      </div>
    </>
  );
};

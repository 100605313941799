import React, { useState } from "react";
import scss from "./Modal.module.scss";
import close from "../../assets/close.svg";
import success from "../../assets/success.png";
import failure from "../../assets/failure.svg";
import InputMask from "react-input-mask";
import axios from "axios";

export const Modal = ({ modalIsOpen, setModalOpen }) => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailureModal, setFailureModal] = useState(false);
  const letters = /^[A-Za-zа-яА-Я\s]+$/;

  const handleTextInput = (event) => {
    const result = event.target.value;
    if (result.match(letters) || result === "") {
      setName(result);
    }
  };
  const handlePhoneInput = ({ target: { value } }) => setPhone(value);

  const submitForm = (e) => {
    e.preventDefault();
    const bodyFormData = new FormData();
    bodyFormData.append("firstName", name);
    bodyFormData.append("phone", phone);
    if (name.trim() && phone.trim()) {
      axios({
        method: "post",
        url: "https://1st-it.ru/send-mail.php",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(() => {
          setName("");
          setPhone("");
          setModalOpen(false);
          setShowSuccessModal(true);
        })
        .catch(() => {
          setName("");
          setPhone("");
          setModalOpen(false);
          setFailureModal(true);
        });
    }
  };
  return (
    <>
      {showFailureModal && (
        <div
          className={`${scss.modal} ${scss.modal__wrapper_active}`}
          onClick={() => setFailureModal(false)}
        >
          <div
            className={scss.failure_modal_inner}
            onClick={(e) => e.stopPropagation()}
          >
            <img
              className={scss.close}
              src={close}
              alt="close"
              onClick={() => setShowSuccessModal(false)}
            />
            <img src={failure} alt="success sign" />
            <h2 style={{ marginTop: "20px" }}>Что-то пошло не так...</h2>
            <div style={{ width: "96%" }}>
              <p style={{ marginTop: "10px" }}>
                Данные не отправлены. Перепроверьте введëнные поля и повторите
                отправку через несколько минут.
              </p>
            </div>
          </div>
        </div>
      )}
      {showSuccessModal && (
        <div
          className={`${scss.modal} ${scss.modal__wrapper_active}`}
          onClick={() => setShowSuccessModal(false)}
        >
          <div
            className={scss.modal_inner}
            onClick={(e) => e.stopPropagation()}
          >
            <img
              className={scss.close}
              src={close}
              alt="close"
              onClick={() => setShowSuccessModal(false)}
            />
            <img src={success} alt="success sign" />
            <h2 style={{ marginTop: "20px" }}>Спасибо за заявку!</h2>
            <p style={{ marginTop: "10px" }}>
              Мы свяжемся с Вами в ближайшее время!
            </p>
          </div>
        </div>
      )}
      <section
        className={
          scss.modal + " " + (modalIsOpen ? scss.modal__wrapper_active : "")
        }
        onClick={() => setModalOpen(false)}
      >
        <section
          className={scss.modal__wrapper}
          onClick={(e) => e.stopPropagation()}
        >
          <img
            className={scss.close}
            src={close}
            alt="close"
            onClick={() => setModalOpen(false)}
          />
          <section className={scss.modal__left}>
            <div>
              <section className={scss.mail}>
                <a href="mailto:it@1st-it.ru">it@1st-it.ru</a>
                <p>Напишите нам</p>
              </section>
              <section className={scss.phone}>
                <a
                  style={{
                    color: "#fff",
                    textDecoration: "none",
                    fontSize: "18px",
                  }}
                  href="tel: +7 (985) 008 84 84"
                >
                  +7 (985) <span className="blue">008 84 84</span>
                </a>
                <p>Связаться с нами</p>
              </section>
            </div>
          </section>
          <section className={scss.modal__right}>
            <section className={scss.modal__right_top}>
              <h2>Оставить заявку</h2>
              <p>
                Просто заполните поля ниже и мы перезвоним в ближайшее время!
              </p>
            </section>
            <form className={scss.modal__form} action="" onSubmit={submitForm}>
              <input
                type="text"
                placeholder="Как Вас зовут?"
                value={name}
                onChange={handleTextInput}
              />
              <InputMask
                mask="+7 (999) 999 99 99"
                value={phone}
                onChange={handlePhoneInput}
                placeholder="Телефон*"
              ></InputMask>
              <button
                type="submit"
                onClick={() =>
                  window.ym(91747434,'reachGoal','knopka')
                }
              >
                Найти специалиста
              </button>
            </form>
            <p>
              Нажимая кнопку “Найти специалиста” Вы соглашаетесь с{" "}
              <a
                href="https://1ceo.su/wp-content/uploads/2021/12/policy.pdf"
                style={{ color: "#00B1BC" }}
              >
                Политикой конфиденциальности
              </a>
            </p>
          </section>
        </section>
      </section>
    </>
  );
};

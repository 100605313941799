export const technoList = [
    [
        {
            id: 1,
            li: "Data Science",
        },
        {
            id: 2,
            li: "Business Analysis",
        },
        {
            id: 3,
            li: "Machine Learning",
        },
        {
            id: 4,
            li: "Computer Vision",
        },
    ],
    [
        {
            id: 5,
            li: "Blockchain",
        },
        {
            id: 6,
            li: "ИБ/Cyber secutity",
        },
        {
            id: 7,
            li: "Big data (системы и технологии управления данными)",
        },
    ],
];

export const languageList = [
    [
        {
            id: 1,
            li: "С / C++",
        },
        {
            id: 2,
            li: "C#",
        },
        {
            id: 3,
            li: "Ruby",
        },
        {
            id: 4,
            li: ".NET",
        },
        {
            id: 5,
            li: "JavaScript",
        },
        {
            id: 6,
            li: "Java",
        },
        {
            id: 7,
            li: "Python",
        },
        {
            id: 8,
            li: "PHP",
        },
        {
            id: 9,
            li: "Golang",
        },
    ],
    [
        {
            id: 10,
            li: "Android / Kotlin",
        },
        {
            id: 11,
            li: "Flutter",
        },
        {
            id: 12,
            li: "iOS / Swift",
        },
        {
            id: 13,
            li: "1С",
        },
        {
            id: 14,
            li: "Bitrix",
        },
        {
            id: 15,
            li: "Navision",
        },
        {
            id: 16,
            li: "Oracle",
        },
        {
            id: 17,
            li: "SAP",
        },
        {
            id: 18,
            li: "Bitrix24",
        },
    ],
];

export const specList = [
    [
        {
            id: 1,
            li: "Frontend",
        },
        {
            id: 2,
            li: "Backend",
        },
        {
            id: 3,
            li: "QA инженер (тестировщик)",
        },
        {
            id: 4,
            li: "Мобильный разработчик",
        },
        {
            id: 5,
            li: "Project-менеджер / PM ",
        },
        {
            id: 6,
            li: "Product-менеджер",
        },
        {
            id: 7,
            li: "Бизнес-аналитик",
        },
        {
            id: 8,
            li: "DevOps",
        },
        {
            id: 9,
            li: "Системный администратор",
        },
    ],
    [
        {
            id: 10,
            li: "Системный архитектор",
        },
        {
            id: 11,
            li: "Технический директор / СТО",
        },
        {
            id: 12,
            li: "CIO",
        },
        {
            id: 13,
            li: "UI/UX дизайнер",
        },
        {
            id: 14,
            li: "DWH developer/ разработчик КХД",
        },
        {
            id: 15,
            li: "DATA-аналитик",
        },
        {
            id: 16,
            li: "Системный аналитик данных",
        },
        {
            id: 17,
            li: "Архитектор DWH",
        },
    ],
];
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import scss from "./WorkScheme.module.scss";
import videoBG from "../../assets/third_block_Trim.mp4";
import arrowActive from "../../assets/arrowActive.svg";
import arrowNotActive from "../../assets/arrowNotActive.svg";
import finishArrow from "../../assets/finishArrow.svg";

export const WorkScheme = () => {
  const initialState = {
    first: false,
    second: false,
    third: false,
    fourth: false,
  };
  const [active, setActive] = useState(initialState);

  function progress(innerWidth) {
    let elem = document.getElementById("progressBar"),
      width = 1,
      height = 1,
      id = setInterval(progressStatus, 100);
    function progressStatus() {
      if (innerWidth > 425) {
        if (width >= (innerWidth === 1070 ? 90 : 93)) {
          clearInterval(id);
        } else {
          width = width + 0.5;
          elem.style.width = width + "%";
          if (width === 24) {
            setActive({ ...active, first: true });
          } else if (width === 46) {
            setActive({ ...active, first: true, second: true });
          } else if (width === 70) {
            setActive({ ...active, first: true, second: true, third: true });
          } else if (width === (innerWidth >= 1440 ? 93 : 90)) {
            setActive({
              ...active,
              first: true,
              second: true,
              third: true,
              fourth: true,
            });
          }
        }
      }
      if (innerWidth <= 425) {
        if (height >= 90) {
          clearInterval(id);
        } else {
          height = height + 0.5;
          elem.style.width = "1px";
          elem.style.height = height + "%";
          if (height === 24) {
            setActive({ ...active, first: true });
          } else if (height === 46) {
            setActive({ ...active, first: true, second: true });
          } else if (height === 67) {
            setActive({ ...active, first: true, second: true, third: true });
          } else if (height === 90) {
            setActive({
              ...active,
              first: true,
              second: true,
              third: true,
              fourth: true,
            });
          }
        }
      }
    }
  }

  useEffect(() => {
    const windowInnerWidth = window.innerWidth;
    progress(windowInnerWidth);
  }, []);

  return (
    <div id="workScheme" className={scss.main}>
      <section className={scss.title}>
        <p className="subtitle">Рекрутинговое агентство 1it</p>
        <h2 className="h2" style={{ textAlign: "center" }}>
          ПРОЗРАЧНАЯ СХЕМА РАБОТЫ
        </h2>
      </section>
      <div className={scss.progressBarWrapper}>
        <div>
          <section className={scss.steps_wrapper}>
            <section className={scss.steps_title}>
              <p>Бриф с заказчиком/уточнение стека и специфики работы</p>
              <p>Поиск/отбор кандидатов</p>
              <p>Собеседование и оценка кандидата/ тестирование</p>
              <p>
                Представление заказчику/ Назначение собеседований с заказчиком
              </p>
              <p></p>
            </section>
            <section className={scss.steps}>
              <div id="progressBar" className={scss.progressBar}></div>
              <img src={arrowActive} alt="arrow" />
              <img
                src={active?.first ? arrowActive : arrowNotActive}
                alt="arrow"
              />
              <img
                src={active?.second ? arrowActive : arrowNotActive}
                alt="arrow"
              />
              <img
                src={active?.third ? arrowActive : arrowNotActive}
                alt="arrow"
              />
              <div>
                <img
                  src={active?.fourth ? finishArrow : arrowNotActive}
                  alt="arrow"
                />
                {active?.fourth ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                    }}
                  >
                    <span className={scss.line_active}></span>
                    <p className={scss.solve}>Решение о найме</p>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                    }}
                  >
                    <span className={scss.line}></span>
                    <p className={scss.notResolve}>Решение о найме</p>
                  </div>
                )}
              </div>
            </section>
          </section>
        </div>
      </div>
      <video src={videoBG} autoPlay loop muted></video>
    </div>
  );
};

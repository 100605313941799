import React, { useEffect } from "react";
import scss from "./WhoAreWe.module.scss";
import WOW from "wowjs";
import { logos } from "../../contants/PLlogosList";

export const WhoAreWe = () => {
  useEffect(() => {
    new WOW.WOW().init();
  }, []);

  return (
    <>
      <section className={scss.allWrapper}>
        <div className={scss.logos}>
          {logos.map((logo) => (
            <img
              className="wow pulse"
              data-wow-iteration="10"
              data-wow-offset="10"
              data-wow-duration="2s"
              src={logo.src}
              alt="logo"
              key={logo.id}
              style={{ ...logo.style }}
            />
          ))}
        </div>
        <div className={scss.wrapper}>
          <section className={scss.WhoAreWe}>
            <h1>КТО МЫ</h1>
            <p>
              Мы команда IT рекрутеров со всего мира, и мы знаем, как искать и
              как найти лучших!
            </p>
            <span>Для нас нет границ в подборе!</span>
          </section>
        </div>
      </section>
    </>
  );
};

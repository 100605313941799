import React from "react";
import scss from "./Footer.module.scss";
import footerLogo from "../../assets/Group.svg";

export const Footer = () => {
  return (
    <footer className={scss.footer_wrapper}>
      <div className={scss.footer}>
        <section className={scss.footer_left}>
          <img src={footerLogo} alt="footer logo" />
          <p>
            Агентство "1iT" это команда профессионалов из it-индустрии, любящих
            свою работу. Наше дело— это наша жизнь. Мы помогаем нашим партнёрам
            в подборе ИТ персонала по всему миру и развиваемся вместе с ними. У
            нас очень широкий спектр работы с позициями- от рядовых
            разработчиков сервисов до уникальных и редких специалистов.
          </p>
        </section>

        <section className={scss.footer_right}>
          <section className={scss.contacts}>
            <section className={scss.contacts_mail}>
              <a href="mailto:it@1st-it.ru">it@1st-it.ru</a>
              <p>Напишите нам</p>
            </section>
            <section className={scss.contacts_phoneNum}>
              <a
                style={{ textDecoration: "none", fontSize: "15px" }}
                href="tel: +7 (985) 008 84 84"
              >
                +7 (985) <span className="blue">008 84 84</span>
              </a>
              <p>Связаться с нами</p>
            </section>
          </section>
        </section>
      </div>
      <div className={scss.footer_bottom}>
        <p>ООО ”1IT” 2022. Все права защищены</p>
        <a
          href="https://1ceo.su/wp-content/uploads/2021/12/policy.pdf"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
        >
          Политика конфиденциальности
        </a>
        <p>
          Сайт разработан{" "}
          <a
            href="https://sector.business/"
            style={{ color: "#fff", textDecoration: "none" }}
          >
            Сектор бизнеса
          </a>{" "}
        </p>
      </div>
    </footer>
  );
};

export const subTitles = [
    {
        p: "Помогаем компаниям во всем мире найти своего IT специалиста",
        key: 1,
    },
    {
        p: "Соединяем профессионалов IT сферы и бизнес",
        key: 2,
    },
    {
        p: "Защищаем интересы работника и работодателя",
        key: 3,
    },
];
import React, { useState } from "react";
import scss from "./WeAreTrusted.module.scss";

import "swiper/swiper-bundle.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import { companies } from "../../contants/companiesLogoList";

export const WeAreTrusted = () => {
  const [isHovering, setHovering] = useState(null);

  const handleHover = (id) => {
    setHovering(id);
  };

  const handleUnHovering = () => {
    setHovering(false);
  };

  const sizeCheck =
    window.innerWidth <= 695 && window.innerWidth > 490
      ? 2
      : window.innerWidth <= 490
      ? 1
      : 5;
  return (
    <section className={scss.wrapper}>
      <h1>Нам доверяют!</h1>
      <p>
        Компании, которые доверяют нам поиск специалистов уже не первый год:
      </p>
      <section className={scss.companies_wrapper}>
        <Swiper
          slidesPerView={sizeCheck}
          spaceBetween={5}
          slidesPerGroup={sizeCheck}
          speed={window.innerWidth < 575 ? 2000 : 3000}
          pagination={{
            clickable: true,
          }}
          loop={true}
          loopFillGroupWithBlank={true}
          autoplay={{
            delay: window.innerWidth < 575 ? 2000 : 3000,
            disableOnInteraction: false,
          }}
          className="mySwiper2"
          modules={[Autoplay, Pagination]}
        >
          {companies.map((img) => (
            <SwiperSlide key={img.id}>
              <div
                className={scss.imgWrapper}
                onMouseOver={() => handleHover(img.id)}
                onMouseOut={handleUnHovering}
              >
                <img
                  style={{
                    display: isHovering === img.id ? "none" : "inline",
                    width: img?.style?.width,
                    height: img?.style?.height,
                  }}
                  className={scss.img}
                  src={img.img}
                  alt="img"
                />
                <img
                  style={{
                    display: isHovering !== img.id ? "none" : "inline",
                    width: img?.style?.width,
                    height: img?.style?.height,
                  }}
                  className={scss.img}
                  src={img.hovered}
                  alt="img"
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
    </section>
  );
};

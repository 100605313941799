/* eslint-disable react-hooks/exhaustive-deps */
import scss from "./WhyUs.module.scss"
import videoBg from "../../assets/second_block_Trim_compressed (1).mp4";
import React, { useEffect, useRef, useState } from "react";

const WhyUs = ({ setModalOpen }) => {
  const ref = useRef();
  const ref2 = useRef();
  const [maxLineHeight, setMaxLineHeight] = useState(1);
  const [currentLineHeight, setCurrentLineHeight] = useState(0);
  const [ads, setAds] = useState([]);
  const [directionDown, setDirectionDown] = useState(true);
  useEffect(() => {
    // console.log(ref.current.querySelectorAll(`.${scss.adv}`)[ref.current.querySelectorAll(`.${scss.adv}`).length - 1].getBoundingClientRect().top - ref.current.getBoundingClientRect().top);
    setMaxLineHeight((ref.current.querySelectorAll(`.${scss.adv}`)[ref.current.querySelectorAll(`.${scss.adv}`).length - 1].getBoundingClientRect().top - ref.current.getBoundingClientRect().top + 6));
    setAds(ref.current.querySelectorAll(`.${scss.adv}`))
    setCurrentLineHeight(1);
  }, [])
  useEffect(() => {
    ads.forEach((el, index, array) => {
      if (ref2.current.getBoundingClientRect().bottom >= el.getBoundingClientRect().top) {
        el.classList.add(scss.active)
        if (array[index + 1]) {
          array[index + 1].classList.add(scss.pre_active)
        }
      } else {
        el.classList.remove(scss.active)
        if (array[index + 1]) {
          array[index + 1].classList.remove(scss.pre_active)
        }
      }
    })
  }, [currentLineHeight])

  useEffect(() => {
    if (directionDown) {
      if (currentLineHeight < maxLineHeight) {
        ref2.current.style.height = currentLineHeight + "px";
        setTimeout(() => {
          setCurrentLineHeight(prev => prev + 0.5);
        }, 10)
      }
      else {
        ads[ads.length - 1].classList.add(scss.active)
        setTimeout(() => { setDirectionDown(false) }, 1000)
      }
    }
    else {
      if (currentLineHeight > 0) {
        ref2.current.style.height = currentLineHeight + "px";
        setTimeout(() => {
          setCurrentLineHeight(prev => prev - 1);
        }, 10)
      } else {
        setTimeout(() => { setDirectionDown(true) }, 1000)
      }
    }

  }, [currentLineHeight, directionDown])
  return (
    <div className={scss.block}>
      <div className="container_md">
        <p className="subtitle text-center">Рекрутинговое агентство 1it</p>
        <h2 className="h2 text-center" style={{ marginBottom: "63px", textTransform: "uppercase", marginTop: "5px" }}>Почему стоит выбрать нас?</h2>
        <div className={scss.advantages} ref={ref}>
          <div className={scss.line} ref={ref2}></div>

          <div className={scss.adv_fake}></div>

          <div className={`${scss.adv} ${scss.active}`}>
            <div className={scss.decor_dot}></div>
            <p>Мы представлены во всех часовых поясах, поэтому работаем в формате 24/7</p>
          </div>

          <div className={`${scss.adv}`}>
            <div className={scss.decor_dot}></div>
            <p>Все проекты ведутся без предоплаты, оплата по факту выхода кандидата</p>
          </div>

          <div className={`${scss.adv_fake} ${scss.pre_active}`}>
          </div>

          <div className={scss.adv_fake}></div>

          <div className={scss.adv}>
            <div className={scss.decor_dot}></div>
            <p>Первые кандидаты будут подобраны и готовы к собеседованию в течение 3-х дней</p>
          </div>

          <div className={scss.adv}>
            <div className={scss.decor_dot}></div>
            <p>Все проекты защищены гарантийной заменой в период испытательного срока</p>
          </div>

          <div className={scss.adv_fake}>
          </div>

          <div className={scss.adv_fake}></div>

          <div className={scss.adv}>
            <div className={scss.decor_dot}></div>
            <p>Подбор осуществляют консультанты IT с глубоким знанием специализации</p>
          </div>

          <div className={scss.adv}>
            <div className={scss.decor_dot}></div>
            <p>Каждый проект курируется руководителем группы, в конце проекта проводится оценка
              эффективности поиска и удовлетворенности клиента результатом</p>
          </div>

          <div className={scss.adv_fake}>
          </div>

          <div className={scss.adv_fake}></div>

          <div className={scss.adv}>
            <div className={scss.decor_dot}></div>
            <p style={{ marginBottom: "5px" }}>У нас неограниченные возможности</p>
            <span>МЫ НАЙДЕМ ЛЮБОГО</span>
          </div>
        </div>
        <div className={scss.last_button}>
          <button onClick={() => setModalOpen(true)} className="btn btn_v1">Найти</button>
        </div>
      </div>
      <video src={videoBg} autoPlay loop muted />
    </div>

  )
}

export default WhyUs
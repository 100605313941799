import React from "react";
import {
  languageList,
  specList,
  technoList,
} from "../../contants/ListsForSearchParams";
import scss from "./SearchParams.module.scss";

export const SearchParams = ({ setModalOpen }) => {
  return (
    <>
      <div className={scss.searchWrapper}>
        <span>Рекрутинговое агентство 1it</span>
        <h1>
          {window.innerWidth > 768
            ? "Ведём поиск по любым параметрам"
            : "ШИРОКИЙ ПОИСК КАНДИДАТОВ"}
        </h1>
        <section className={scss.cards_wrapper}>
          <div className={scss.spec}>
            <span>Для нас нет границ в подборе!</span>
            <h2>СПЕЦИАЛИЗАЦИЯ</h2>
            <div>
              {specList.map((spec, index) => (
                <ul key={index}>
                  {spec.map((item) => (
                    <li key={item.id}>{item.li}</li>
                  ))}
                </ul>
              ))}
            </div>
          </div>
          <div className={scss.language}>
            <div className={scss.language_top}>
              <span>Найдем именно то, что нужно ВАМ</span>
              <h2>ЯЗЫК</h2>
              <div>
                {languageList.map((language, index) => (
                  <ul key={index}>
                    {language.map((item) => (
                      <li key={item.id}>{item.li}</li>
                    ))}
                  </ul>
                ))}
              </div>
            </div>
            <div className={scss.spec_find}>
              <span>Не откладывайте на потом!</span>
              <p>
                Оставить заявку на поиск специалиста прямо сейчас, и мы займемся
                поиском незамедлительно!
              </p>
              <button onClick={() => setModalOpen(true)}>
                Найти специалиста
              </button>
            </div>
          </div>
          <div className={scss.techno}>
            <span>Найдем именно то, что нужно ВАМ</span>
            <h2>ТЕХНОЛОГИЯ</h2>
            <div>
              {technoList.map((tech, index) => (
                <ul key={index}>
                  {tech.map((item) => (
                    <li key={item.id}>{item.li}</li>
                  ))}
                </ul>
              ))}
            </div>
          </div>
          <div className={scss.level}>
            <span>Найдем именно то, что нужно ВАМ</span>
            <h2>УРОВНИ</h2>
            <ul>
              <li>Junior</li>
              <li>Middle</li>
              <li>Senior, Team-Lead, CTO/CIO </li>
            </ul>
          </div>
        </section>
      </div>
    </>
  );
};

import img from "../assets/tech_isons/1c.svg";
import js from "../assets/tech_isons/js.svg";
import flutter from "../assets/tech_isons/flutter.svg";
import python from "../assets/tech_isons/python.svg";
import bitrix from "../assets/tech_isons/bitrix.svg";
import php from "../assets/tech_isons/php.svg";
import java from "../assets/tech_isons/java.svg";
import oracle from "../assets/tech_isons/oracle.svg";
import ruby from "../assets/tech_isons/ruby.svg";
import csharp from "../assets/tech_isons/csharp.svg";
import sap from "../assets/tech_isons/sap.svg";
import bitrix24 from "../assets/tech_isons/bitrix24.svg";

export const logos = [
    {
        id: 1,
        src: js,
        style: {
            position: "absolute",
            top: "110px",
            left: "-4%",
        }
    },
    {
        id: 2,
        src: flutter,
        style: {
            position: "absolute",
            top: "280px",
            left: "-16%",
        }
    },
    {
        id: 3,
        src: python,
        style: {
            position: "absolute",
            top: "415px",
            left: "-4%",
        }
    },
    {
        id: 4,
        src: bitrix,
        style: {
            position: "absolute",
            top: "555px",
            left: "-13%",
        }
    },
    {
        id: 5,
        src: php,
        style: {
            position: "absolute",
            top: "555px",
            left: "22%",
        }
    },
    {
        id: 6,
        src: java,
        style: {
            position: "absolute",
            top: "580px",
            left: "53%",
        }
    },
    {
        id: 7,
        src: img,
        style: {
            position: "absolute",
            top: "405px",
            right: "-15%",
        }
    },
    {
        id: 8,
        src: oracle,
        style: {
            position: "absolute",
            top: "270px",
            right: "-5%",
        }
    },
    {
        id: 9,
        src: ruby,
        style: {
            position: "absolute",
            top: "120px",
            right: "-8%",
        }
    },
    {
        id: 10,
        src: csharp,
        style: {
            position: "absolute",
            top: "100px",
            right: "25%",
        }
    },
    {
        id: 11,
        src: sap,
        style: {
            position: "absolute",
            top: "75px",
            left: "47%",
        }
    },
    {
        id: 12,
        src: bitrix24,
        style: {
            position: "absolute",
            top: "120px",
            left: "22%",
        }
    },
];
export const companies = [
    {
        id: 21,
        img: "/images/company_logos/als.png",
        hovered: "/images/company_logos/als_hov.png",
        style: {
            width: "150px",
            height: "150px",
        },
    },
    {
        id: 22,
        img: "/images/company_logos/instr.png",
        hovered: "/images/company_logos/instr_hov.png",
    },
    {
        id: 23,
        img: "/images/company_logos/lenta.png",
        hovered: "/images/company_logos/lenta_hov.png",
    },
    {
        id: 24,
        img: "/images/company_logos/megafon.png",
        hovered: "/images/company_logos/megafon_hov.png",
    },
    {
        id: 25,
        img: "/images/company_logos/reg.png",
        hovered: "/images/company_logos/reg_hov.png",
        style: {
            width: "150px",
            height: "50px",
        },
    },
    {
        id: 26,
        img: "/images/company_logos/stc.png",
        hovered: "/images/company_logos/stc_hov.png",
    },
    {
        id: 1,
        img: "/images/company_logos/миррико2.png",
        hovered: "/images/company_logos/миррико.png",
        style: {
            width: "fit-content",
            height: "fit-content",
        },
    },
    {
        id: 2,
        img: "/images/company_logos/ко фактор2.png",
        hovered: "/images/company_logos/ко фактор.png",
        style: {
            width: "fit-content",
            height: "fit-content",
        },
    },
    {
        id: 4,
        img: "/images/company_logos/DPD-Emblem2.png",
        hovered: "/images/company_logos/DPD-Emblem.png",
        style: {
            width: "fit-content",
            height: "fit-content",
        },
    },
    {
        id: 5,
        img: "/images/company_logos/ксоред2.png",
        hovered: "/images/company_logos/ксоред.png",
        style: {
            width: "fit-content",
            height: "fit-content",
        },
    },
    {
        id: 6,
        img: "/images/company_logos/магнит2.png",
        hovered: "/images/company_logos/магнит.png",
        style: {
            width: "100px",
            height: "100px",
        },
    },
    {
        id: 7,
        img: "/images/company_logos/beeline2.png",
        hovered: "/images/company_logos/beeline.png",
        style: {
            width: "fit-content",
            height: "fit-content",
        },
    },
    {
        id: 8,
        img: "/images/company_logos/отто2.png",
        hovered: "/images/company_logos/отто.png",
        style: {
            width: "fit-content",
            height: "fit-content",
        },
    },
    {
        id: 9,
        img: "/images/company_logos/la-redoute2.png",
        hovered: "/images/company_logos/la-redoute.png",
        style: {
            width: "fit-content",
            height: "fit-content",
        },
    },
    {
        id: 10,
        img: "/images/company_logos/водоканал2.png",
        hovered: "/images/company_logos/водоканал.png",
        style: {
            width: "120px",
            height: "120px",
        },
    },
    {
        id: 11,
        img: "/images/company_logos/облфарм2.png",
        hovered: "/images/company_logos/облфарм.png",
        style: {
            width: "fit-content",
            height: "fit-content",
        },
    },
    {
        id: 12,
        img: "/images/company_logos/рм рейл2.png",
        hovered: "/images/company_logos/рм рейл.png",
        style: {
            width: "170px",
            height: "170px",
        },
    },
    {
        id: 13,
        img: "/images/company_logos/технопроект2.png",
        hovered: "/images/company_logos/технопроект.png",
        style: {
            width: "fit-content",
            height: "fit-content",
        },
    },
    {
        id: 14,
        img: "/images/company_logos/х52retail.png",
        hovered: "/images/company_logos/х5retail.png",
        style: {
            width: "170px",
            height: "170px",
        },
    },
    {
        id: 15,
        img: "/images/company_logos/alti2.png",
        hovered: "/images/company_logos/alti.png",
        style: {
            width: "fit-content",
            height: "fit-content",
        },
    },
    {
        id: 16,
        img: "/images/company_logos/Ekoniva-logo2.png",
        hovered: "/images/company_logos/Ekoniva-logo.png",
        style: {
            width: "fit-content",
            height: "fit-content",
        },
    },
    {
        id: 17,
        img: "/images/company_logos/keaz2.png",
        hovered: "/images/company_logos/keaz.png",
        style: {
            width: "fit-content",
            height: "fit-content",
        },
    },
    {
        id: 18,
        img: "/images/company_logos/logo-Transmashholding2.png",
        hovered: "/images/company_logos/logo-Transmashholding.png",
        style: {
            width: "fit-content",
            height: "fit-content",
        },
    },
    {
        id: 19,
        img: "/images/company_logos/pek.png",
        hovered: "/images/company_logos/pek2.png",
        style: {
            width: "fit-content",
            height: "fit-content",
        },
    },
    {
        id: 20,
        img: "/images/company_logos/rag2.png",
        hovered: "/images/company_logos/rag.png",
        style: {
            width: "fit-content",
            height: "fit-content",
        },
    },
];
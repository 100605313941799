import { useState } from 'react';
import './App.css';
import { Footer } from './components/Footer/Footer';
import { Main } from './components/Main/Main';
import { Modal } from './components/Modal/Modal';
import { SearchParams } from './components/SearchParams/SearchParams';
import { WeAreTrusted } from './components/WeAreTrusted/WeAreTrusted';
import { WhoAreWe } from './components/whoAreWe/WhoAreWe';
import WhyUs from "./components/WhyUs/WhyUs";
import { WorkScheme } from './components/WorkScheme/WorkScheme';

function App() {
  const [modalIsOpen, setModalOpen] = useState(false);
  return (
    <>
      <Main setModalOpen={setModalOpen} />
      <WhoAreWe  />
      <WhyUs setModalOpen={setModalOpen} />
      <SearchParams setModalOpen={setModalOpen} />
      <WorkScheme />
      <WeAreTrusted />
      <Footer />
      <Modal modalIsOpen={modalIsOpen} setModalOpen={setModalOpen} />
    </>
  );
}

export default App;
